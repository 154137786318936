import React, { useEffect } from 'react';
import VendorModal from 'react-modal';
import classNames from 'classnames';

import styles from './index.module.scss';

const Modal = (props) => {
  const {
    children,
    portalClassName,
    overlayClassName,
    className,
    closeIconClassName,
    onRequestClose,
    newCloseBtn,
    ...rest
  } = props;

  useEffect(() => {
    VendorModal.setAppElement('#root');
  }, []);

  const closeModal = () => {
    if (typeof onRequestClose === 'function') {
      onRequestClose();
    }
  };

  return (
    <VendorModal
      portalClassName={classNames(styles['wrapper'], portalClassName)}
      overlayClassName={classNames(styles['overlay'], overlayClassName)}
      className={classNames(styles['content'], className)}
      onRequestClose={onRequestClose}
      {...rest}
    >
      {onRequestClose && !newCloseBtn && (
        <span
          className={classNames(styles['close-icon'], closeIconClassName)}
          onClick={closeModal}
          onKeyDown={closeModal}
        />
      )}
      {children}
    </VendorModal>
  );
};

export default Modal;
