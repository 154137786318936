import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'delayed-scroll-restoration-polyfill/index.es6';

import React from 'react';
import { createRoot } from "react-dom/client";
import {Provider} from 'react-redux'
import flagsmith from 'flagsmith'
import { BrowserRouter } from 'react-router-dom';
import {FlagsmithProvider} from 'flagsmith/react'

import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";
import store from './store'
import {
  getAllPermissions,
  getCompanyProfile,
  getUserProfile,
  getUserStatus
} from "./actions";
import App from './App';
import { getApiBaseFeatureFlagsKey, getSentryApiUrl, getSentryBaseEnv } from './utils/utils';
import { isFormulasPage } from "./utils/isFormulasPage";

const user = JSON.parse(sessionStorage.getItem(
    'user-info-for-verified'
));
const token = user?.access

Sentry.init({
  dsn: getSentryApiUrl(),
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: getSentryBaseEnv(),
  tracesSampleRate: 1.0,
});

if (!sessionStorage.getItem('tabId')) {
  sessionStorage.setItem('tabId', Date.now().toString());
}

if (token) {
  Sentry.setUser({ email: user?.user?.email });
    store.dispatch(getUserProfile());
    store.dispatch(getAllPermissions(false, isFormulasPage()));
    store.dispatch(getCompanyProfile());
} else {
    store.dispatch(getUserStatus());
    window.sessionStorage.removeItem('Overdue')
    Sentry.setUser({});
}

const removeLoginIfInactive = () => {
  const user = sessionStorage.getItem('user-info-for-verified');

  if (!user) {
    window.localStorage.removeItem('login');
  }
};

window.addEventListener('load', removeLoginIfInactive);
window.addEventListener('beforeunload', removeLoginIfInactive);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <FlagsmithProvider
      options={{
        environmentID: getApiBaseFeatureFlagsKey(),
        cacheFlags:true
      }}
      flagsmith={flagsmith}
    >
      <BrowserRouter>
        <App flagsmith={flagsmith} />
      </BrowserRouter>
    </FlagsmithProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
