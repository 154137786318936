import React from 'react';
import {connect} from 'react-redux';

import {removeError} from '../../../actions';

import styles from './index.module.scss';

const ErrorLine = (props) => {

  const {errors} = props;

  if (errors.length === 0) {
    return null;
  }

  return (
    <div className={styles['holder']}>
      <div className={styles['holder__inner']}>
        {errors.map(({key, message}) =>
          <div key={crypto.randomUUID()} className={styles['msg']}>
            {message}
            <div
              className={styles['msg__close']}
              onClick={() => props.removeError(key)}
              onKeyDown={() => props.removeError(key)}
            />
          </div>
        )}
      </div>
    </div>
  );

};

export default connect(
  state => ({
    errors: state.general.errors
  }),
  dispatch => ({
    removeError: (key) => dispatch(removeError(key))
  })
)(ErrorLine);

