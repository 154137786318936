/* eslint-disable no-unused-vars */

import React, {Component} from 'react';
import Cookies from 'universal-cookie';
import {Link} from "react-router-dom";
import classNames from 'classnames';

import Button from "../Button_v2";

import styles from './index.module.scss';

const cookies = new Cookies();

class CookiesAccept extends Component {
    state = {
        hideCookiesAccept: false
    }

    acceptCookie = () => {
        cookies.set('gfp-cookies-accepted', 1, {maxAge: 86400 * 365});
        const subscriptPopUp = document.querySelector('.-subscribe-popup');

        if (subscriptPopUp) {
            subscriptPopUp.style.bottom = 0;
        }

        this.setState({
            hideCookiesAccept: true
        });
    }

    render() {
        const { hideCookiesAccept } = this.state;

        return hideCookiesAccept ? '' : (
            <div className={classNames(styles['cookies-accept'], 'cookies-accept')}>
                <div className={styles['cookies-accept__container']}>
                    <div className={styles['cookies-accept__info']}>
                        The Good Face Project uses cookies to ensure we are giving you the best experience on our site.
                        <Link to="/f_privacy_policy" target="_blank"> Learn more</Link>
                    </div>
                    <Button
                        className={styles['cookies-accept__btn']}
                        onClick={this.acceptCookie}
                    >
                        Accept
                    </Button>
                </div>
            </div>
        );
    }
};

export default CookiesAccept;
