import React from 'react';
import {Link} from "react-router-dom";
import classNames from "classnames";

import {ReactComponent as Arrow} from "../../assets/img/Icons/Misc/LinkArrow.svg";

import styles from "./index.module.scss";

const Button = (props) => {

  let {
    tag = 'button',
    children,
    href = false,
    external = false,
    className,
    onClick,
    link = false,
    hasArrow = false,
    loader = false,
    btnStyle = 'primary',
    ...rest
  } = props;

  if (link === true) {
    btnStyle = 'link';
  }

  let btnClass = classNames(className, {
    [styles['button']]: !link,
    [styles['button--primary']]: btnStyle === 'primary',
    [styles['button--secondary']]: btnStyle === 'secondary',
    [styles['button--loading']]: !link && loader,
    [styles['link-button']]: btnStyle === 'link'
  });

  let opt = {
    ...rest,
    className: btnClass
  };

  if (onClick) {
    opt['onClick'] = onClick
  }

  if (link || hasArrow) {
    children = [<span key='btn-text'>{children}</span>, <Arrow key="btn-arrow" className={styles.arrow}/>];
  }

  if (href && !external) {
    return <Link to={href} {...opt}>{children}</Link>
  } else {

    if (href) {
      tag = 'a';
      opt['href'] = href;
    }

    return React.createElement(tag, opt, children);

  }

};

export default Button;
