import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { connect } from 'react-redux';

import PageBase from '../../PageBase';
import { Container } from '../../../components/Layout';
import {getBetaLabel} from "../../../utils/utils";
import {
  COMPANY_PERMISSION_OPTIONS,
  USER_PERMISSION_OPTIONS,
} from '../../FormulatorAdminProfilePermissionsEditor/const';
import { checkPermissions, REPORTS } from '../../../utils/checkPermissions';

import styles from './index.module.scss';

class FormulatorMenu extends Component {
  render() {
    const {
      pageName,
      isHeaderMenu,
      isMenuOpen,
      user,
      featureFlags,
      companyPermissions,
      setIsOpenMenu
    } = this.props;

    if (!user) {
      return null;
    }

    const inMarketFeatureFlag = featureFlags?.in_market_products?.enabled;
    const SUPPLIER_BASIC = !!companyPermissions?.find((item) => item.name === 'supplier_basic_account'),
      BLACKLIST = !!user?.permissions?.find((item) => item.name === USER_PERMISSION_OPTIONS.PRIVATE_CHEMICAL_POLICE),
      companyFormulaFormulas = !!companyPermissions?.find((item) => item.name === COMPANY_PERMISSION_OPTIONS.FORMULAS),
      createEditRM = !!companyPermissions?.find((item) => item.name === COMPANY_PERMISSION_OPTIONS.RM_CREATE_EDIT),
      IN_MARKET_PRODUCTS = inMarketFeatureFlag && !!companyPermissions?.find((item) => item.name === 'consumer_dbs'),
      CARA_RM_COMBINATOR = !!companyPermissions?.find((item) => item.name === 'cara_access'),
      COMPANY_PARTNER_SHIPS = !!companyPermissions?.find((item) => item.name === 'partnerships'),
      USER_FORMULA = !!user?.permissions?.find((item) => item.name === USER_PERMISSION_OPTIONS.FORMULAS),
      USER_RM = !!user?.permissions?.find((item) => item.name === USER_PERMISSION_OPTIONS.RM),
      USER_PARTNERSHIPS = !!user?.permissions?.find((item) => item.name === USER_PERMISSION_OPTIONS.PARTNERSHIPS),
      USER_INMARKET = !!user?.permissions?.find((item) => item.name === USER_PERMISSION_OPTIONS.IN_MARKET),
      USER_TNL = !!user?.permissions?.find((item) => item.name === USER_PERMISSION_OPTIONS.TNL),
      companyTNLPermission = !!companyPermissions?.find((item) => item.name === COMPANY_PERMISSION_OPTIONS.TNL)
      const { reportsDisabled } = checkPermissions({userPermissions: user?.permissions, companyPermissions, type: REPORTS})

    return (
      <PageBase
        className={classNames(
          styles['f-admin__menu'],
          { [styles['-header-menu']]: isHeaderMenu },
          { [styles['-open']]: isMenuOpen },
        )}
      >
        <Container
          id="menu_container"
          onClick={() => isHeaderMenu && setIsOpenMenu(false)}
        >
          {!SUPPLIER_BASIC && USER_FORMULA && companyFormulaFormulas && (
            <Link
              className={classNames(styles['f-admin__menu-item'], {
                [styles['-active']]: pageName === 'formulas' || isHeaderMenu,
              })}
              to="/fa/formulas"
            >
              <div
                className={classNames(
                  styles['f-admin__menu-icon'],
                  styles['-formulas'],
                )}
              />
              My formulas
            </Link>
          )}
          {createEditRM && USER_RM  &&(
            <Link
              className={classNames(styles['f-admin__menu-item'], {
                [styles['-active']]:
                  pageName === 'raw-material' ||
                  pageName === 'raw-material-view' ||
                  isHeaderMenu,
              })}
              to="/fa/raw-material"
            >
              <div
                className={classNames(
                  styles['f-admin__menu-icon'],
                  styles['-raw-materials'],
                )}
              />
              My Raw Materials
            </Link>
          )}
          {!SUPPLIER_BASIC && COMPANY_PARTNER_SHIPS && USER_PARTNERSHIPS && (
            <Link
              className={classNames(styles['f-admin__menu-item'], {
                [styles['-active']]: pageName === 'partners' || isHeaderMenu,
              })}
              to="/fa/partners/sharing"
            >
              <div
                className={classNames(
                  styles['f-admin__menu-icon'],
                  styles['-partners'],
                )}
              />
              My Partners
            </Link>
          )}
          {reportsDisabled &&
            <Link
              className={classNames(styles['f-admin__menu-item'], {
                [styles['-active']]: pageName === 'reports' || isHeaderMenu,
              })}
              to="/fa/reports"
            >
              <div
                className={classNames(
                  styles['f-admin__menu-icon'],
                  styles['-reports'],
                )}
              />
              My Reports
            </Link>
          }
          {BLACKLIST && (
            <Link
              className={classNames(styles['f-admin__menu-item'], {
                [styles['-active']]:
                  pageName === 'policies' ||
                  pageName === 'policies-view' ||
                  isHeaderMenu,
              })}
              to="/fa/policies"
            >
              <div
                className={classNames(
                  styles['f-admin__menu-icon'],
                  styles['-policies'],
                )}
              />
              My Chemical Policies
            </Link>
          )}
          {companyTNLPermission  && USER_TNL && (
            <Link
              className={classNames(styles['f-admin__menu-item'], {
                [styles['-active']]:
                  pageName === 'library' ||
                  pageName === 'library-view' ||
                  isHeaderMenu,
              }, styles['-library'])}
              to="/fa/library"
            >
              <div
                className={classNames(
                  styles['f-admin__menu-icon'],
                  styles['-library'],
                )}
              />
              <div>
                Trade name Library -{' '}
                <span className={styles['f-admin__menu-beta']}>
                  {getBetaLabel()}
                </span>
              </div>
            </Link>
          )}
          {IN_MARKET_PRODUCTS && USER_INMARKET && (
            <Link
              className={classNames(styles['f-admin__menu-item'], {
                [styles['-active']]:
                  pageName === 'in-market-products' ||
                  pageName === 'in-market-products-view' ||
                  isHeaderMenu,
              })}
              to="/fa/in-market-products"
            >
              <div
                className={classNames(
                  styles['f-admin__menu-icon'],
                  styles['-consumer'],
                )}
              />
              In-Market Products
            </Link>
          )}
          <Link
            className={classNames(styles['f-admin__menu-item'], {
              [styles['-active']]:
                pageName === 'consumer-study' || isHeaderMenu,
            })}
            to="/fa/consumer-study"
          >
            <div
              className={classNames(
                styles['f-admin__menu-icon'],
                styles['-consumer-study'],
              )}
            />
            My Consumer Studies
          </Link>
          {featureFlags?.trash.enabled &&
            <Link
              className={classNames(styles['f-admin__menu-item'], styles['-trash'], {
                [styles['-active']]: pageName === 'trash' || isHeaderMenu,
              })}
              to="/fa/trash"
            >
              <div
                className={classNames(
                  styles['f-admin__menu-icon'],
                  styles['-trash'],
                )}
              />
              Trash
            </Link>
          }
          {CARA_RM_COMBINATOR && (
            <Link
              className={classNames(styles['f-admin__menu-item'], {
                [styles['-active']]:
                  pageName === 'insights' ||
                  pageName === 'cara' ||
                  isHeaderMenu,
              })}
              to="/fa/cara"
            >
              <div
                className={classNames(
                  styles['f-admin__menu-icon'],
                  styles['-insights'],
                )}
              />
              <div>
                CARA -{' '}
                <span className={styles['f-admin__menu-beta']}>
                 {getBetaLabel()}
                </span>
              </div>
            </Link>
          )}
        </Container>
      </PageBase>
    );
  }
}

export default connect(
  state => ({
    featureFlags: state.user.featureFlags,
  })
)(FormulatorMenu);
