import { LogOutAction } from "../utils/logout";

import { refreshToken, getUserProfile, getAllPermissions } from "./formulator";
import { GLOBAL_ERROR } from "./types";

export function getConfig(data) {
    const user = JSON.parse(sessionStorage.getItem('user-info-for-verified'));
    const token = user?.access;

    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        ...(data && { data: data })
    };
}

export function catchFunction(data) {
    if (data?.error?.response?.status === 401
        && data.error.response.statusText === 'Invalid authorization token, Signature has expired'
    ) {
        data.dispatch(refreshToken()).then(
            data.dispatch(data.refreshFunction),
        );
    } else if (data?.error?.response?.data === '401: Unauthorized') {
        LogOutAction();
    } else {
        if (data?.error?.response?.status === 403) {
            if (
              data.error.response.data?.errors === 'User is no longer a company admin.' ||
              data.error.response.data?.message === 'User is no longer a company user.' ||
              data.error.response.data?.message?.toLowerCase().includes('your account is not active')
            ) {
                LogOutAction(true);
            } else {
                data.dispatch(getUserProfile());
                data.dispatch(getAllPermissions());
            }
        }

        data.dispatch({
            type: GLOBAL_ERROR,
            data: data?.error?.response?.request?.response,
        });
    }
}

export const arrayTypeQueryParams = (queryName, data = [], andSign = true) => {
    if (!data?.length || !queryName) return '';

    const stringifiedData = data.join(`&${queryName}=`);

    return `${andSign ? '&' : ''}${queryName}=${stringifiedData}`;
}

export const singleTypeQueryParams = (queryName, data, condition = false, andSign = true) => {
    if (!queryName || !condition) return '';

    return `${andSign ? '&' : ''}${queryName}=${data}`;
}
