import { ACCOUNT_LIMITED_POLICY_MESSAGE } from '../../../data/messages';

export const REPORTS = {
  'free_of': 'Formulated Without',
  'compliance': 'Regulatory Compliance',
  'benefits': 'Benefits',
  'qq_complete_formula': 'QQ',
  'formula_card': 'ELF Formula Card',
}

export const LIMITED_ACC_MESSAGES ={
  TOXIN_GROUPS:
    ACCOUNT_LIMITED_POLICY_MESSAGE,
  REPORTS:
      'This type of report is not available for basic accounts. ' +
      'If you would like to be able to create these reports, please upgrade your access.',
}

export const NO_PRODUCT_TYPE_MESSAGE = 'Please add the product type in order to generate reports.'

export const SORTING_TYPES = {
  NAME_ASC: 'name',
  NAME_DESC: '-name',
  NEW_FIRST: '-modified_at',
  OLD_FIRST: 'modified_at',
  CENTRIC_INTERNAL_STATUS_ASC: 'centric_internal_status',
  CENTRIC_INTERNAL_STATUS_DESC: '-centric_internal_status',
  NUM_ASC: 'formula_num',
  NUM_DESC: '-formula_num',
  STATUS_ASC: 'status',
  STATUS_DESC: '-status',
}

export const DOTS_MENU_LOADER_TYPES = {
  COPY_TO_IN_MARKET: 'copy_to_in_market',
}

export const FORMULA_COLUMN_SWITCHER_NAMES = {
  product_name: 'Product Name',
  brand_name: "Brand Name",
  formula: 'Formula #',
  status: 'Status',
  product_type: 'Product Type',
  shared: 'Shared Status',
  owner: 'Owner',
  last_update: 'Last Updated',
}

export const DEFAULT_TABLE_LAYOUT = {
  product_name: true,
  formula: true,
  status: false,
  brand_name: false,
  product_type: true,
  shared: true,
  owner: false,
  last_update: false,
}
